/**
 *
 * NAVEGAÇÃO GERAL
 *
 */
// scroll geral
$(document).ready(function() {

	$('#fullpage').fullpage({
		scrollOverflow: true,
		scrollingSpeed: 500,
		sectionSelector: '.pageSection',
		navigation: true,
		slidesNavigation: true,
		normalScrollElements: '#jobModal',
		normalScrollElementTouchThreshold: 5,
		responsiveWidth: 1170,
		responsiveHeight: 620,
		afterResponsive: function(isResponsive){
			$('.pageSection').addClass('responsiveActive');
		},
	});
});

$(document).on('scroll', function() {
	$('.fixedEl__brandMenu').addClass('scrolled');	
});

function changeContentOnHashChange() {
	var hashAtual = window.location.hash;
	var contentTooltip = '';
	var destinyTooltip = '';

	switch (hashAtual) {
		case '#inicio':
		case "#home":
		case "":
			contentTooltip = 'Quem somos';
			$('.fixedEl__swiperNext .captionPage').text(contentTooltip);
			$('.fixedEl__btnMute').show();
			$('.fixedEl__btnPlay').show();
			document.getElementById("videoHome").play();
			$('.fixedEl').removeClass('fixedEl--yellowBg');
			break;

		case '#quem':
			contentTooltip = 'Portfólio';
			$('.fixedEl__swiperNext .captionPage').text(contentTooltip);
			$('.fixedEl__btnMute').hide();
			$('.fixedEl__btnPlay').hide();
			$('.fixedEl').addClass('fixedEl--yellowBg');
			$('.fixedEl__navMenu a').removeClass('active');
			$('.fixedEl__navMenu a[href="#quem"]').addClass('active');
			break;

		case '#oque':
			contentTooltip = 'Encontre-nos';
			$('.fixedEl__swiperNext .captionPage').text(contentTooltip);
			$('.fixedEl__btnMute').hide();
			$('.fixedEl__btnPlay').hide();
			$('.fixedEl').removeClass('fixedEl--yellowBg');
			$('.fixedEl__navMenu a').removeClass('active');
			$('.fixedEl__navMenu a[href="#oque"]').addClass('active');
			break;

		case '#onde':
			contentTooltip = 'Envie-nos um e-mail';
			$('.fixedEl__swiperNext .captionPage').text(contentTooltip);
			$('.fixedEl__btnMute').hide();
			$('.fixedEl__btnPlay').hide();
			$('.fixedEl').removeClass('fixedEl--yellowBg');
			$('.fixedEl__navMenu a').removeClass('active');
			$('.fixedEl__navMenu a[href="#onde"]').addClass('active');
			break;

		default:
			$('.fixedEl__btnMute').show();
			$('.fixedEl__btnPlay').show();
	}	
}
$(window).on('hashchange', function() {changeContentOnHashChange()});
$(window).on('load', function() {changeContentOnHashChange()});

// Menu superior 
$('.fixedEl__brandMenu .c-hamburguer').on('click', function() {
	$(this).toggleClass('is-active');
	$('.fixedEl__navMenu').toggleClass('is-open');
	$('.fixedEl__brandMenu .brand').toggle();
	$('#fullpage').toggleClass('rotateNavOpen');
});

$('.fixedEl__navMenu a').on('click', function() {
	$('.fixedEl__navMenu a').removeClass('active');
	$(this).addClass('active');

	setTimeout(function () {
		$('.fixedEl__brandMenu .c-hamburguer').removeClass('is-active');
		$('.fixedEl__navMenu').removeClass('is-open');
		$('.fixedEl__brandMenu .brand').show();
		$('#fullpage').removeClass('rotateNavOpen');
	}, 800);

	if ($(this).hasClass('openForm')) {abreFormContato();}
});

// botoes flutuantes de navegacao
$('.fixedEl__swiperNext').on('click', function() {
	var hashAtual = window.location.hash;
	console.log(hashAtual);
	switch (hashAtual) {
		case '#inicio':
		case "#home":
		case "":
			$.fn.fullpage.moveTo('quem');
			break;

		case '#quem':
			$.fn.fullpage.moveTo('oque');
			break;

		case '#oque':
			$.fn.fullpage.moveTo('onde');
			break;

		case '#onde':
		case '#vem':
			abreFormContato();
			break;
	}	
});

/**
 *
 * HOME
 *
 */

var video = document.getElementById("videoHome");

$(window).on('load', function() {
	video.play();
	$('#videoHome').addClass('videoHome--show');
});

$('.fixedEl__btnPlay').on('click', function() {
	var mediaVideo = $("#videoHome").get(0);
	if (mediaVideo.paused) {
		mediaVideo.play();
	} else {
		mediaVideo.pause();
	}
});

$('.fixedEl__btnMute').on('click', function() {
	$('.fixedEl__btnMute i').toggleClass('is-muted');	
	if(video.muted){
		video.muted = false;
	} else {
		video.muted = true;
	}
});

// faz sumir os controles de video no mobile
if((is.mobile()) || (is.touchDevice())) {
	$(document).on('scroll', function() {
		var heightSection = $('#home').height();
		var offsetTopControls = $('.fixedEl__btnMute').offset();
		if(offsetTopControls.top > heightSection) {
			$('.fixedEl__btnMute').addClass('hide');
			$('.fixedEl__btnPlay').addClass('hide');
		} else if (offsetTopControls.top <= heightSection){
			$('.fixedEl__btnMute').removeClass('hide');
			$('.fixedEl__btnPlay').removeClass('hide');
		}
	});
}

/**
 *
 * SOBRE 
 *
 */
var swiperSobre = new Swiper('.photoGallery__swiper', {
	direction: 'horizontal',
	slidesPerView: 3,
	spaceBetween: 20,
	pagination: {
		el: '.photoGallery__pagination',
		clickable: true,
	},
	autoplay: {
		delay: 2500,
		disableOnInteraction: false,
	},
	breakpoints: {
		660: {
			slidesPerView: 2,
		},
	}
});

/**
 *
 * GRID PORTFOLIO
 *
 */

// Filtro - Portfolio
$('.js-job_catLi').on('click', function(e) {
	e.preventDefault();
	var el_tags = $('.js-job_catLi');
	var el_allTag = $('.js-job_catLi-all');
	var el_jobsTagged = $('.js-jobGrid');
	var selectedCategory = $(this).attr('data-job');

	// Alterna a categoria ativa no menu
	el_tags.removeClass('active');
	$(this).addClass('active');

	if ($(this).hasClass('allJobs')) {
		$('.js-hideAllGrids').show('fast');
		setTimeout(function(){
			el_jobsTagged.removeClass('js-hideAllGrids');
			el_jobsTagged.addClass('js-selectedGrids');
		}, 250);		
	} else {		
		// show / hide jobs no grid
		$('.js-hideAllGrids').show('fast');
		el_jobsTagged.addClass('js-hideAllGrids');
		el_jobsTagged.removeClass('js-selectedGrids');

		setTimeout(function(){
			el_jobsTagged.removeClass('js-alignGrids');
			$(".js-jobGrid[data-job="+selectedCategory+"]").removeClass('js-hideAllGrids');
			$(".js-jobGrid[data-job="+selectedCategory+"]").addClass('js-alignGrids');
		}, 350);

		setTimeout(function(){
			$('.js-hideAllGrids').hide('fast');
		}, 500);

		setTimeout(function(){
			$(".js-jobGrid[data-job="+selectedCategory+"]").addClass('js-selectedGrids');
		}, 1000);
	}
});

/**
 *
 * MODAL DO PORTFOLIO
 *
 */

function resizeReveal(){
	//setTimeout(function() {
		var elmnt = document.getElementById("beforePat1");
		var newWidth = elmnt.clientWidth;
		$('.jobModal__beforeAfter .pat2').css('width',newWidth+'px');
		console.log(newWidth,' teste hj');
	//}, 500);
}

// Modal infos job
var jsonUrl = 'dist/js/jobs.json';
function ajaxTaitoJobs(jobDestiny) {
	// window.location.hash = "#job_" + jobDestiny;
	window.history.pushState({url: "?i=" + jobDestiny + "#job_" + jobDestiny + ""}, '', "?i=" + jobDestiny + "#job_" + jobDestiny);

	// oculta btn de voltar job caso for o primeiro
	if (jobDestiny <= 0) {$('.jobModal__controls--prev').hide();}
	else {$('.jobModal__controls--prev').show();}

	// oculta btn de avançar o job caso for o ultimo
	var jsonQtdJobs = $('.js-jobGrid').length - 1;
	if (jobDestiny >= jsonQtdJobs) {$('.jobModal__controls--next').hide();}
	else {$('.jobModal__controls--next').show();}

	// seta os destinos dos botoes prev/next
	$('.jobModal__controls--prev').attr('data-jobprev', parseInt(jobDestiny) - 1);
	$('.jobModal__controls--next').attr('data-jobnext', parseInt(jobDestiny) + 1);

	// busca as informações no json
	$.ajax({
		type: "GET",
		url: jsonUrl,
		datatype: 'json',
        cache: false,
		beforeSend: function() {
			$('.jobModal__loader').addClass('active');
		},
		success: function(data) {
			// carrega o conteudo do job
			$('.jobModal__header h2').text(data[jobDestiny].job_title);
			$('.jobModal__beforeAfter .pat2').css('background', 'url(media/img/content/jobs/' + data[jobDestiny].img_before + ')');
			$('.jobModal__beforeAfter .pat1').css('background', 'url(media/img/content/jobs/' + data[jobDestiny].img_after + ')');
			$('.jobModal__video iframe').attr('src', 'https://www.youtube.com/embed/'+data[jobDestiny].job_video); //
			$('.jobModal__presentation.part_1 img').attr('src', 'media/img/content/jobs/'+data[jobDestiny].img_presentation_1);
			$('.jobModal__presentation.part_2 img').attr('src', 'media/img/content/jobs/'+data[jobDestiny].img_presentation_2);
			$('.jobModal__quote p').text(data[jobDestiny].job_text);
			// init before after
			new BeerSlider(document.querySelector(".jobBeforeAfter"));

			// verifica variaveis vazias
			if (data[jobDestiny].img_before == '' || 
				data[jobDestiny].img_before == undefined || 
				data[jobDestiny].img_after == '' || 
				data[jobDestiny].img_after == undefined) {
				$('.jobModal__beforeAfter').hide();}
				else {
					$('.jobModal__beforeAfter').show();
				}

			if (data[jobDestiny].job_video == '' || 
				data[jobDestiny].job_video == undefined) {
				$('.jobModal__video').hide();}
				else {$('.jobModal__video').show();}

			if (data[jobDestiny].job_text == '' || 
				data[jobDestiny].job_text == undefined) {
				$('.jobModal__quote').hide();}
				else {$('.jobModal__quote').show();}

			if (data[jobDestiny].img_presentation_1 == '' || 
				data[jobDestiny].img_presentation_1 == undefined) {
				$('.jobModal__presentation.part_1').hide();}
				else {$('.jobModal__presentation.part_1').show();}

			if (data[jobDestiny].img_presentation_2 == '' || 
				data[jobDestiny].img_presentation_2 == undefined) {
				$('.jobModal__presentation.part_2').hide();}
				else {$('.jobModal__presentation.part_2').show();}
		},
		error: function(xmlHttpRequest, textStatus, errorThrown) {
			alert('Erro');
		},
		complete: function() {
			setTimeout(function(){
				$('.jobModal__loader').removeClass('active');
				$('.jobModal__hero').removeClass('transitionNextPrev');
				$('#jobModal').addClass('active').removeClass('u-dnone');
				resizeReveal();
			}, 1000);
		}
	});
}

$('.js-jobGrid').on('click', function() {
	$('body').css('overflow', 'hidden');
	$.fn.fullpage.setAllowScrolling(false);
	ajaxTaitoJobs(parseInt($(this).attr('data-jobcode'), 10));
});

$('.jobModal__controls--prev').on('click', function() {
	ajaxTaitoJobs(parseInt($(this).attr('data-jobprev'), 10));	
});

$('.jobModal__controls--next').on('click', function() {
	ajaxTaitoJobs(parseInt($(this).attr('data-jobnext'), 10));	
});

$('.jobModal__controls--next, .jobModal__controls--prev').on('click', function() {
	$('.jobModal__hero').addClass('transitionNextPrev');
});

$('.jobModal__controls--close, .jobModal__mask').on('click', function() {
	$('.jobModal__loader').hide('fast');
	$('#jobModal').removeClass('active');
	$('body').css('overflow', 'auto');
	$.fn.fullpage.setAllowScrolling(true);
	$.fn.fullpage.moveTo('oque');
	//window.location.hash = "#oque";
	$('.jobModal__video iframe').attr('src', '');
	window.history.pushState({url: "./#oque"}, '', "./#oque");
});
$(window).on('load', function() {
	var hashAtual = window.location.hash.split('_');
	var urlJob = hashAtual[0] + '_';
	var codeJob = hashAtual[1];
	var hashSearch = window.location.search.split('=');
	if (hashSearch[0] === '?i') {
		var part= hashSearch[1].split('&');//caso url venha do facebook
		ajaxTaitoJobs(part[0], 10);
		//console.log(part[0],'search test');
	}else if(urlJob === "#job_"){
		ajaxTaitoJobs(codeJob, 10);
	}
});

$(window).on('resize', function() {
	resizeReveal();
});

/**
 *
 * CONTATO
 *
 */

$('.foneMask').focusout(function () {
	var phone, element;
	element = $(this);
	element.unmask();
	phone = element.val().replace(/\D/g, '');
	if (phone.length > 10) {
		element.mask("(99) 99999-999?9");
	} else {
		element.mask("(99) 9999-9999?9");
	}
}).trigger('focusout');

$("#formContato").validate({
	submitHandler: function (form) {
		//SUBMIT form
		$(form).ajaxSubmit({
			//target: 'status-envio',
			beforeSubmit: function () {
				$('#status-envio').show();
				$('#status-envio').html('<span class="c-stsSend__feedback c-stsSend__feedback--load"> Enviando, aguarde...</span>');
				$('#btnContato').attr('disabled', true);
			},
			success: function (result) {
				result = result.split('|');
				if(parseInt(result[0].charAt(result[0].length-1)) == 1){
					$('#status-envio').html(result[1]);
					setTimeout(function(){
						$('#btnContato').attr('disabled',false);
						$("#formContato .field").val('');
						$("#formContato textarea").val('').html('');
						$("#formContato .field").removeClass('error');
						$('#status-envio').hide();
						$('#status-envio').empty();
						$('.contatoModal__controls--close').trigger('click');
					},2000);
				}else{
					$('#status-envio').html(result);
					setTimeout(function () {
						$('#status-envio').hide();
						$('#status-envio').empty();
						$('#btnContato').attr('disabled', false);
					}, 3000);
				}
			}
		});
	}
});

 function abreFormContato() {
 	window.location.hash = '#vem';
 	$('#contatoModal').addClass('active').removeClass('u-dnone');
 	$('body').css('overflow', 'hidden');
	$.fn.fullpage.setAllowScrolling(false);
 	//console.log('teste');
 }

 $(window).on('load', function() {
 	if(window.location.hash == '#vem') {
 		abreFormContato();
 		$.fn.fullpage.moveTo('onde');
 	}
 });

 $('.contatoModal__controls--close').on('click', function() {
 	$('#contatoModal').removeClass('active');
 	$('body').css('overflow', 'auto');
	$.fn.fullpage.setAllowScrolling(true);
 	$.fn.fullpage.moveTo('onde');
 	window.location.hash = '#onde'
 });

$('.fixedEl__btnContato a').on('click', function() {
	$.fn.fullpage.moveTo('onde');
});

$('#contato .btnEmail span').on('click', function() {
	abreFormContato();
});

// aumenta area de dragMap do contato
$(window).on('load', function() {
	var containerWidth = $('#contato .u-container').width();
	var maxRowWidth = $('body').width();
	var newLeft = (maxRowWidth - containerWidth) / 2;
	$('#contato .u-container').css({
		left: newLeft,
		display: 'inline-block',
		width: 'auto'
	});
});